<template>
    <v-dialog v-model="dialog" max-width="800">
        <v-card>
        <v-card-title >
            Contact information
        </v-card-title>
        <v-card-text>
            <v-row>
                <v-col cols="12">
                    <div class="font-15 mt-5">
                        <strong>Full name: </strong> {{ addressBook.full_name }}
                    </div>

                    <div class="font-15 mt-3">
                        <strong>Occupation: </strong> {{ addressBook.occupation ? addressBook.occupation : 'NA' }}
                    </div>

                    <div class="font-15 mt-3">
                        <strong>Type: </strong> <span class="text-capitalize">{{ addressBook.type ? addressBook.type : 'NA' }}</span>
                    </div>

                    <div class="font-15 mt-3">
                        <strong>Status: </strong> 
                        <span class="badge" :class="addressBook.is_active ? 'badge-success':'badge-danger'">
                            {{ addressBook.is_active ? 'Active' : 'Inactive' }}
                        </span>
                    </div>

                    <div class="font-15 mt-3">
                        <strong>Phone: </strong> {{ addressBook.phone ? addressBook.phone : 'NA' }}
                    </div>

                    <div class="font-15 mt-3">
                        <strong>Mobile: </strong> {{ addressBook.mobile ? addressBook.mobile : 'NA' }}
                    </div>

                    <div class="font-15 mt-3">
                        <strong>Work phone: </strong> {{ addressBook.work_phone ? addressBook.work_phone : 'NA' }}
                    </div>

                    <div class="font-15 mt-3">
                        <strong>Email: </strong> {{ addressBook.email ? addressBook.email : 'NA' }}
                    </div>

                    <div class="font-15 mt-3">
                        <strong>Address: </strong> {{ addressBook.address ? addressBook.address : 'NA' }}
                    </div>

                    <div class="font-15 mt-3">
                        <strong>Address line 1: </strong> {{ addressBook.address_line_1 ? addressBook.address_line_1 : 'NA' }}
                    </div>

                    <div class="font-15 mt-3">
                        <strong>Address line 2: </strong> {{ addressBook.address_line_2 ? addressBook.address_line_2 : 'NA' }}
                    </div>

                    <div class="font-15 mt-3">
                        <strong>City: </strong> {{ addressBook.city ? addressBook.city : 'NA' }}
                    </div>

                    <div class="font-15 mt-3">
                        <strong>District: </strong> {{ addressBook.district ? addressBook.district : 'NA' }}
                    </div>

                    <div class="font-15 mt-3">
                        <strong>State: </strong> {{ addressBook.state ? addressBook.state : 'NA' }}
                    </div>

                    <div class="font-15 mt-3">
                        <strong>Country: </strong> {{ addressBook.country_name ? addressBook.country_name : 'NA' }}
                    </div>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
            <v-col cols="12">
                <div class="float-right">
                    <v-btn 
                        @click="hideModal" 
                        class="btn btn-standard cancel-btn" 
                        depressed
                    >
                        Close
                    </v-btn>
                </div>
            </v-col>
        </v-card-actions>
        </v-card>
  </v-dialog>    
</template>


<script>
export default {
    data(){
        return {
            dialog: false,
            addressBook: ''
        }
    },
    methods:{
        hideModal(){
            this.dialog = false;
        },
        showModal(addressBook){
            this.dialog = true;
            this.addressBook = addressBook;
        }
    }
}
</script>

<style scoped>
    .font-15{
        font-size: 15px;
    }
</style>
