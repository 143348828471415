<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Contacts</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\
                  Contacts
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn
                    @click="addAddressBook()"
                    class="mt-4 btn btn-primary"
                    style="color: #fff"
                  >
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon
                    >&nbsp; Add new
                  </v-btn>
                  &nbsp;&nbsp;
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div
              class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start"
            >
              <div class="row">
                <v-col cols="12" md="4">
                  <v-text-field
                    class="form-control"
                    v-model="search.name"
                    label="Name, phone, email"
                    outlined
                    clearable
                    v-on:keyup.enter="getAddressBook()"
                    dense
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <v-select
                    v-model="search.type"
                    label="Type"
                    :items="types"
                    item-text="name"
                    item-value="value"
                    outlined
                    clearable
                    v-on:keyup.enter="getAddressBook()"
                    dense
                  >
                  </v-select>
                </v-col>

                <v-col cols="12" md="4">
                  <v-btn
                    :loading="loading"
                    @click.prevent="getAddressBook()"
                    class="btn btn-primary w-35 float-right"
                    dark
                  >
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon
                    >&nbsp; Search
                  </v-btn>
                </v-col>
              </div>
            </div>
            <div class="responsive">
              <table class="table">
                <thead>
                  <tr class="px-3">
                    <th class="wrap-column px-3">Full name</th>
                    <th class="wrap-column px-3">Email</th>
                    <th class="wrap-column px-3">Mobile/phone</th>
                    <th class="wrap-column px-3">Type</th>
                    <th class="px-3">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    :key="index"
                    v-for="(address_book, index) of address_books"
                  >
                    <td class="wrap-column px-3">
                      <a @click="quickView(address_book)" href="#">
                        {{ address_book.full_name }}
                        <i
                          :class="
                            address_book.is_active
                              ? 'dot-active'
                              : 'dot-inactive'
                          "
                          class="fas fa-circle"
                        ></i>
                      </a>
                    </td>
                    <td class="wrap-column px-3">{{ address_book.email }}</td>
                    <td class="wrap-column px-3">
                      {{ address_book.phone || address_book.mobile }}
                    </td>
                    <td class="wrap-column text-capitalize px-3">
                      {{ address_book.type }}
                    </td>

                    <td>
                      <b-dropdown
                        no-caret
                        no-flip
                        right
                        size="sm"
                        toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                        variant="link"
                      >
                        <template v-slot:button-content>
                          <slot>
                            <span> <i class="flaticon-more-1"></i> </span
                          ></slot>
                        </template>
                        <!--begin::Navigation-->
                        <div class="navi navi-hover">
                          <b-dropdown-text class="navi-item" tag="div">
                            <a
                              @click="editAddressBook(address_book)"
                              class="navi-link"
                              href="#"
                            >
                              <span class="navi-icon">
                                <i class="flaticon-edit"></i>
                              </span>
                              <span class="navi-text"> Edit </span>
                            </a>
                          </b-dropdown-text>

                          <b-dropdown-text
                            class="navi-item"
                            tag="div"
                            v-if="checkIsAccessible('address-book', 'delete')"
                          >
                            <a
                              @click="deleteAddressBook(address_book.id)"
                              class="navi-link"
                              href="#"
                            >
                              <span class="navi-icon">
                                <i class="fas fa-trash ml-1"></i>
                              </span>
                              <span class="navi-text"> Delete</span>
                            </a>
                          </b-dropdown-text>
                        </div>
                        <!--end::Navigation-->
                      </b-dropdown>
                    </td>
                  </tr>
                  <tr v-if="address_books.length == 0">
                    <td colspan="6" class="text-center">
                      <strong>No data available to display.</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
              <b-pagination
                v-if="address_books.length > 0"
                class="pull-right mt-7"
                :per-page="perPage"
                :total-rows="total"
                @input="getAddressBook"
                first-number
                last-number
                v-model="page"
              ></b-pagination>
            </div>
          </div>

          <create-and-update
            @refresh="getAddressBook"
            ref="address-book"
          ></create-and-update>

          <quick-view ref="quick-view"></quick-view>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import CreateAndUpdate from "./CreateAndUpdate";
import AddressBookService from "@/core/services/address/AddressBookService";
import QuickView from "./QuickView";

const addressbook = new AddressBookService();
export default {
  name: "address-book",

  components: {
    CreateAndUpdate,
    QuickView,
  },
  data() {
    return {
      address_books: [],
      page: null,
      perPage: null,
      total: null,

      search: {
        name: "",
        type: "",
      },
      types: [
        {
          name: "Other",
          value: "other",
        },
        {
          name: "Vendor",
          value: "vendor",
        },
      ],
      loading: false,
    };
  },
  mounted() {
    this.getAddressBook();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "address-book", route: "wizard-1" },
      { title: "address-book" },
    ]);
  },
  methods: {
    getAddressBook() {
      this.loading = true;
      addressbook
        .paginate(this.search, this.page)
        .then((response) => {
          this.address_books = response.data.data;
          this.page = response.data.meta.current_page;
          this.total = response.data.meta.total;
          this.perPage = response.data.meta.per_page;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          // console.log(error);
        });
    },
    quickView(address_book) {
      this.$refs["quick-view"].showModal(address_book);
    },
    addAddressBook() {
      this.$refs["address-book"].createAddressBook();
    },
    editAddressBook(address_book) {
      this.$refs["address-book"].editAddressBook(address_book);
    },

    deleteAddressBook(id) {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            addressbook
              .delete(id)
              .then((response) => {
                this.getAddressBook();
              })
              .catch((error) => {
                //console.log(error);
              });
          }
        },
      });
    },
    checkMove: function (evt) {
      console.log(evt);
      let futureIndex = parseInt(evt.draggedContext.futureIndex) + 1;
      let item = evt.draggedContext.element;
      let itemId = item.id;
      this.sort(itemId, futureIndex);
    },
    sort(id, index) {
      addressbook
        .sort(id, index)
        .then((res) => {
          this.$snotify.success("Sorted");
          this.getAddressBook();
        })
        .catch((err) => {
          this.$snotify.success("Sorted Error");
        });
    },
    detail(id) {
      this.$router.push({ name: "address_book", params: { id: id } });
    },
  },
};
</script>
<style scoped>
.buttons {
  margin-top: 35px;
}
.fa-circle:before {
  content: "\f111";
  position: relative;
  left: 3em;
}
</style>
